import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function Subirimagengaleria() {
  const [file, setFile] = useState(null);
  const [token, setToken] = useState('');
  const [nombre, setNombre] = useState('');
  const [categoria, setCategoria] = useState('');
  const [ubicacion, setUbicacion] = useState('');
  const [tipo, setTipo] = useState('');
  const [genero, setGenero] = useState('');
  const [especie, setEspecie] = useState('');
  const [message, setMessage] = useState('');

  // Función para obtener el token de las cookies al cargar la página
  useEffect(() => {
    const storedToken = Cookies.get('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleNombreChange = (e) => {
    setNombre(e.target.value);
  };

  const handleCategoriaChange = (e) => {
    setCategoria(e.target.value);
  };

  const handleUbicacionChange = (e) => {
    setUbicacion(e.target.value);
  };

  const handleTipoChange = (e) => {
    setTipo(e.target.value);
  };

  const handleGeneroChange = (e) => {
    setGenero(e.target.value);
  };

  const handleEspecieChange = (e) => {
    setEspecie(e.target.value);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('nombre', nombre);
    formData.append('categoria', categoria);
    formData.append('ubicacion', ubicacion);
    formData.append('tipo', tipo);
    formData.append('genero', genero);
    formData.append('especie', especie);

    try {
      const response = await fetch('https://api.universeantmx.com.mx/pyapi/uploadgaleria', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: token,
        },
      });

      if (response.ok) {
        setMessage('Carga exitosa');
      } else {
        setMessage('Error al cargar');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error al cargar');
    }
  };

  return (
    <div className="container">
      <h1 className="mt-5">Carga de Imagen con Información</h1>
      <form>
        <div className="form-group">
          <input type="file" className="form-control-file" onChange={handleFileChange} />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Nombre"
            value={nombre}
            onChange={handleNombreChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Categoría"
            value={categoria}
            onChange={handleCategoriaChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Ubicación"
            value={ubicacion}
            onChange={handleUbicacionChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Tipo"
            value={tipo}
            onChange={handleTipoChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Género"
            value={genero}
            onChange={handleGeneroChange}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Especie"
            value={especie}
            onChange={handleEspecieChange}
          />
        </div>
        <button type="button" className="btn btn-primary" onClick={handleUpload}>
          Subir
        </button>
        <p className="mt-3">{message}</p>
      </form>
    </div>
  );
}

export default Subirimagengaleria;

