import React from 'react';

function Productos() {
  return (
    <div>
      <h1>Productos</h1>
      <p>Bienvenido a la página de inicio.</p>
    </div>
  );
}

export default Productos;
