import React from 'react';

function Principal() {
  return (
    <div>
      <h1>Principal</h1>
      <p>Somos una empresa comprometida con...</p>
    </div>
  );
}

export default Principal;