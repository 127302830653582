import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Principal from './views/Principal';
import Galeria from './views/Galeria';
import Productos from './views/Productos';
import Mascotas from './views/Mascotas';
import Usuarioslogin from './views/Usuarioslogin';
import Subirimagengaleria from './views/Subirimagengaleria';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Principal />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/mascotas" element={<Mascotas />} />
          <Route path="/productos" element={<Productos />} />
          <Route path="/usuarioslogin" element={<Usuarioslogin />} />
          <Route path="/subirimggaleria" element={<Subirimagengaleria />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


