import React from 'react';

function Mascotas() {
  return (
    <div>
      <h1>Mascotas</h1>
      <p>Somos una empresa comprometida con...</p>
    </div>
  );
}

export default Mascotas;
