import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Galeria() {
  const [data, setData] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Todos');
  const [selectedGenre, setSelectedGenre] = useState('Todos');
  const [selectedSpecies, setSelectedSpecies] = useState('Todos');

  useEffect(() => {
    fetch('https://api.universeantmx.com.mx/pyapi/downloadgaleria')
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error:', error));
  }, []);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const generateOptions = (key) => {
    const uniqueOptions = [...new Set(data.map((item) => item[key]))];
    return ['Todos', ...uniqueOptions];
  };

  return (
    <div className="container-lg mt-5">
      <h1>Galería de Imágenes e Información</h1>
      <div className="row">
        <div className="col-md-2">
          <form>
            <div className="form-group">
              <label htmlFor="categoria">Categoría:</label>
              <select
                id="categoria"
                className="form-control"
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                {generateOptions('categoria').map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="genero">Género:</label>
              <select
                id="genero"
                className="form-control"
                onChange={(e) => setSelectedGenre(e.target.value)}
              >
                {generateOptions('genero').map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="especie">Especie:</label>
              <select
                id="especie"
                className="form-control"
                onChange={(e) => setSelectedSpecies(e.target.value)}
              >
                {generateOptions('especie').map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </div>
        <div className="col-md-9">
          <div className="row">
            {data
              .filter((item) => {
                return (
                  (selectedCategory === 'Todos' || item.categoria === selectedCategory) &&
                  (selectedGenre === 'Todos' || item.genero === selectedGenre) &&
                  (selectedSpecies === 'Todos' || item.especie === selectedSpecies)
                );
              })
              .map((item, index) => (
                <div key={index} className="col-md-6 mb-6">
                  <div className="card">
                    <img
                      src={`http://api.universeantmx.com.mx/public/${item.imagen}`}
                      alt={item.nombre}
                      className="card-img-top"
                      onClick={() => openLightbox(index)}
                      style={{ cursor: 'pointer' }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{item.nombre}</h5>
                      <p className="card-text">Categoría: {item.categoria}</p>
                      <p className="card-text">Ubicación: {item.ubicacion}</p>
                      <p className="card-text">Tipo: {item.tipo}</p>
                      <p className="card-text">Género: {item.genero}</p>
                      <p className="card-text">Especie: {item.especie}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={`http://api.universeantmx.com.mx/public/${data[photoIndex].imagen}`}
          nextSrc={`http://api.universeantmx.com.mx/public/${data[(photoIndex + 1) % data.length].imagen}`}
          prevSrc={`http://api.universeantmx.com.mx/public/${data[(photoIndex + data.length - 1) % data.length].imagen}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + data.length - 1) % data.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % data.length)
          }
        />
      )}
    </div>
  );
}

export default Galeria;




















